import React from 'react';
import { Link } from 'gatsby';

import logo from '../img/logo.svg';
// import facebook from '../img/social/facebook.svg';
const Footer = class extends React.Component {
  render() {
    return (
      <footer style={{ borderTop: '1px solid #e3e1da' }}>
        <div style={{ display: 'flex', fontSize: '12px' }}>
          <div
            className="column is-4"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <p>
              Copyright © 2020 Jade Medical Clinic Pte Ltd. All rights reserved
            </p>
          </div>
          <div className="column is-4" style={{ display: 'flex' }}>
            <Link to="/" className="navbar-item">
              Home
            </Link>
            <Link className="navbar-item" to="/ourservices">
              Our Services
            </Link>
            <Link className="navbar-item" to="/gallery">
              Gallery
            </Link>
            <Link className="navbar-item" to="/contact">
              Contact Us
            </Link>
          </div>
          <div className="column is-4" style={{ display: 'flex' }}>
            <i class="fas fa-map-marked-alt" style={{ marginRight: '20px' }} />
            <p>
              Jade Medical Clinic Pte Ltd <br />8 Eu Tong Sen Street <br />
              #13-99 The Central Office Tower 1 <br />
              Singapore 059818
            </p>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
